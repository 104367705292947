import { useAuthStore } from '~/stores/auth'
import { useUserStore } from '~/stores/user'
import { storeToRefs } from 'pinia'

export default defineNuxtRouteMiddleware(async (to, from) => {
   const { isAuthenticated, lastRoute } = storeToRefs(useAuthStore())

   if (to?.name !== "login") {
      lastRoute.value = to.path
   }

   // console.log(`\n\ndefineNuxtRouteMiddleware(${JSON.stringify(to)}, ${JSON.stringify(from)})
   // to.path = ${JSON.stringify(to.path)}, lastRoute.value = ${lastRoute.value}`)

   // check if Authenticated
   if (!isAuthenticated.value && to?.name !== "login") {
      abortNavigation()
      return navigateTo("/login")
   } else if (isAuthenticated.value && to?.name === "login") {
      return navigateTo("/")
   }
})